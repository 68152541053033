.squarefeet {
font-size: 25px;
font-weight: 500;
}
.MuiPagination-root{
    margin: auto;
    width: 36%;
    margin-top: 20px;

}
.squarefeet span {
margin-left: 25px;
}

form.step1submit input {
margin-bottom: 0 !important;
height: 35px;
}

.nextclass {
background: #0095FF;
color: #fff;
border: 1px solid #0095FF;
}

.modal {
position: fixed;
left: 0;
top: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
opacity: 0;
transition: all 0.3s ease-in-out;
pointer-events: none;
}

.modal.enter-done {
opacity: 1;
pointer-events: visible;
}

.modal.exit {
opacity: 0;
}

.modal-content {
width: 500px;
background-color: #fff;
transition: all 0.3s ease-in-out;
transform: translateY(-200px);
}
p.step1heading {
text-align: center;
font-size: 19px;
}


.first ,.third{
display: flex;
justify-content: space-between;
}



form.step1submit input {
margin-bottom: 20px;
}

.fifth input {
width: 51%;
}

form.step1submit input {
padding: 9px 10px;
border: 1px solid #efefef;
border-radius: 5px;
}

.custom_modal_width {
width: 800px;
padding:10px;
}

.bottom_section label {
float: left;
width: 100%;
text-align: center;
font-size: 22px;
font-weight: 500;
margin: 15px;
}

.bttoncheck {
display: flex;
width: 100%;
justify-content: center;
padding-top: 20px;
padding-bottom: 12px;
}

.btn-green-light{
    background:#6FCF97;
    }

    .btn-green-dark{
        background:#6FCF97;
        }
    .btn-orange-dark{
        background:#f2994a;
        }
        
        .squarefeet {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height:55px;
        }
        .squarefeet input#sqft {
            border: 0;
            font-size: 24px;
            font-weight: 600;
            width: 100%;
            color: #222B45;
            margin-left: 10px;
        }
        .MuiGrid-root.MuiGrid-item.custom-txLabel label {
            font-size: 15px;
            color: #222B45;
            text-align: center;
            font-weight: 400;
            width: 100%;
            display: inline-block;
        }
        .MuiGrid-root.MuiGrid-item.custom-txLabel label sub {
            display: block;
            font-size: 11px;
            font-style: italic;
            font-weight: 400;
        }
        .custom-textLabel {
            border: 1px solid #EFF4FA;
            border-radius: 8px;
            height: 40px;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            display: flex;
            margin-top: 10px;
            align-items: center;
        }
        .MuiGrid-root.MuiGrid-item.custom-total label {
            color: #222B45;
            font-size: 24px;
            font-weight: 600;
        }
.bottom_section button {
    background-color: #E0E0E0;
    border: 0;
    padding: 8px;
    margin-right: 13px;
    color: #484444;
    border-radius: 8px;
}
.bottom_section button:hover {
    background-color: #6FCF97;
    color: #fff;
}
form.step1submit .first input[name="first_name"] {
width: 34%;
}
form.step1submit .first input[name="last_name"] {
width: 64%;
}
form.step1submit .second input[name="address"] {
width: 100%;
}
form.step1submit .third input[name="state"], form.step1submit .third input[name="zip_code"], form.step1submit .third input[name="city"] {
width: 32%;
}
form.step1submit .fourth input, form.step1submit .fifth input {
width: 50%;
}

.custom_modal_width {
padding: 0;
border-radius: 5px;
}
.custom_modal_width .modal-header {
padding: 20px 15px;
}
.custom_modal_width .modal-header h4.modal-title {
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 16px;
text-align: left;
color: #222B45;
}
p.step1heading {
text-align: center;
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 16px;
margin: 22px 0;
}
.custom_modal_width .modal-body {
padding: 0;
}
.custom_modal_width form.step1submit {
padding: 15px;
}

.first input{
width:47%;
}
.second input{
width:47%;
}


.topside {
float: left;
width: 100%;
}

.dateclass, .diverlist {
    width: 100%;
}

.dateclassd {
    width: 36%;
}

.dateclassdw {
    width: 33%;
}

button.btn.btn-blue.nextclass11 {
    margin-top: 20px;
}
.firstsection, .seccondsection, .Thirdsection {
    float: left;
    margin: 10px 0px 10px 45px;
    width: 100%;
}

.firstsection input[type="text"], .seccondsection input[type="text"], .Thirdsection input[type="textarea"] {
    float: left;
    padding: 9px 10px;
    border: 1px solid #ccc;
    margin: 5px;
    width: 36%;
}

.bttonlastcheck label {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0 15px 0px;
}

.firstsection label, .seccondsection label, .Thirdsection label {
    /* text-align: center; */
    float: left;
    width: 34%;
}

.leftside {
float: left;
width: 70%;
}

.rightside {
float: right;
width: 30%;
}

.nextclass {
    background: #0095FF;
    color: #fff;
    border: 1px solid #0095FF;
}

.modal.enter-done .modal-content {
transform: translateY(0);
}

.modal.exit .modal-content {
transform: translateY(-200px);
}

.modal-header,
.modal-footer {
padding: 10px;
}

.modal-title {
margin: 0;
}

.modal-body {
padding: 10px;
border-top: 1px solid #eee;
border-bottom: 1px solid #eee;
}

/************** Start new *****************/
form.step1submit .first input[name="first_name"] {
    width: 34%;
}
form.step1submit .first input[name="last_name"] {
    width: 64%;
}
form.step1submit .second input[name="address"] {
    width: 100%;
}
form.step1submit .third input[name="state"], form.step1submit .third input[name="zip_code"], form.step1submit .third input[name="city"] {
    width: 32%;
}
form.step1submit .fourth input, form.step1submit .fifth input {
    width: 50%;
}
.custom_modal_width {
    padding: 0;
    border-radius: 5px;
}
.custom_modal_width .modal-header {
    padding: 20px 15px;
}
.custom_modal_width .modal-header h4.modal-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 16px;
    text-align: left;
    color: #222B45;
}
p.step1heading {
    text-align: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 16px;
    margin: 22px 0;
}
.custom_modal_width .modal-body {
    padding: 0;
}
.custom_modal_width form.step1submit {
    padding: 15px;
}
.bttonfirstcheck {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.bttonfirstcheck button {
    margin: 0 10px;
}

.bttonfirstcheck button.cancelclass, button.cancelclass {
    background: transparent;
    border: 0;
    color: #8F9BB3;
    font-size: 13px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
}

button.btn {
    border-radius: 14px;
    padding: 10px 40px;
    margin-right: 10px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    border: 0;
}

.btn-blue {
    background: #0095FF;
}
.topside .rightside label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    display: block;
    margin-bottom: 15px;
    color: #222B45;
}
.topside .rightside .mainlabel label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 18px;
    margin-bottom: 0;
    text-align: center;
}

.all_button {
    float: left;
    text-align: center;
    width: 100%;
    margin: 25px;
}
.topside .rightside .mainlabel label span.sub-name {
    font-size: 14px;
    display: block;
}
.topside .rightside .mainlabel input[type="text"] {
    width: 99px;
    background: #FFFFFF;
    border: 1px solid #EFF4FA;
    box-sizing: border-box;
    border-radius: 8px;
    height: 34px;
    padding: 0 10px;
}
.rightside {
    float: right;
    width: 40%;
    padding-left: 15px;
    padding-right: 15px;
}
.leftside {
    float: left;
    width: 60%;
    padding-left: 15px;
    padding-right: 15px;
}
.leftside img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mainlabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.text-center {
    text-align: center !important;
}

button.toggledButtonId.toggled {
    background: #6FCF97;
    color: #fff;
}
.MuiButton-containedPrimary {
    background: #0095FF;
}
.viewa{
background-position: center;
width: 40px;
height: 30px;
border: 0;
}


.map-container {
    min-height: 400px;
    }
    .MuiListItemText-root{
        display: none;
    }
.map-container1 {
    height: 800px;
    }

    #geocoder {
        height: auto;
        }

    /* .MuiPaper-root.MuiDrawer-paper.jss98 {
        width: auto;
    } */
    #root nav#navsidebar {
        width: 100px;
    }
    #root nav#navsidebar .MuiPaper-root.MuiDrawer-paper {
        width: auto;
    }
    #root nav#navsidebar:hover {
        width: 280px;
    }
    #root nav#navsidebar:hover .MuiPaper-root.MuiDrawer-paper{
        width: 280px;
    }
    #root nav#navsidebar:hover .MuiPaper-root.MuiDrawer-paper .MuiListItemText-root {
        display: block;
    }

    
    /* #root nav .MuiPaper-root.MuiDrawer-paper ul.MuiList-root .MuiListItem-button .MuiListItemText-root{
        display: none;
    }
    #root nav .MuiPaper-root.MuiDrawer-paper ul.MuiList-root .MuiListItem-button:hover .MuiListItemText-root {
        display: block;
    } */
    button.togglebutton {
        color: #000;
    }
    
    /* nav.makeStyles-drawer-94:hover {
        width: 280px !important;
    }
    nav.makeStyles-drawer-94:hover .makeStyles-drawerPaperLight-98{
        width:280px !important;
    } */
    


@media(min-width:960px){
    #root main#maincheck {
        width: calc(100% - 280px) !important;
        margin-left: -100px !important;
    }
    #root main#maincheck.drawerOpen {
        margin-left: 0 !important;
    }
    main.makeStyles-content-54.makeStyles-contentShift-55 {
        margin-left: 0px !important;
    }
}
/************** End new *****************/
.markereee{
background : url(../images/pin-blue.png);
background-size: contain;
background-position: center center;
background-repeat: no-repeat;
width: 40px;
height: 60px;
display: flex;
padding-top: 10px;
color: #fff;
justify-content: center;
font-size: 16px;
font-weight: bold;
}

.markers-customer{
    background : url(../images/pin-orange.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 40px;
    height: 60px;
    display: flex;
    padding-top: 10px;
    color: #fff;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    }
.markere{
    background : url(../images/pin-yellow.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 40px;
    height: 60px;
    display: flex;
    padding-top: 10px;
    color: #fff;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    }


    .routesdata {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .routesdata .routesselect {
        display: flex;
        justify-content: flex-end;
    }

    .MuiListItemText-root.champ {
        display: block;
    }

    .routesdata .routesselect .MuiFormControl-root {
        width: 200px;
    }

    /* .modal.enter-done {
        z-index: 9999;
    } */

    .selectdriver {
        width: 100%;
    }
    
    .custom_modal_width .modal-body {
        height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .bottom_section button {
        margin-bottom: 5px;
    }

    form.step1service .MuiFormControl-root {
        min-width: 100%;
    }
    
    .step1service button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
        margin-right: 10px;
    }

    .MuiListItemText-root.champ.MuiListItemText-multiline {
        display: block !important;
    }

    .MuiButtonBase-root.MuiListItem-root.logout .MuiListItemText-root {
        display: block;
    }

    .MuiTableHead-root th.ascending::after {
        content: url(../images/sort-down-svg.png);
        display: inline-block;
        margin-left: 1em;
        height: 20px;
      }

      /* .MuiTableHead-root th:after {
        content: url(../images/sort-svg.png);
        display: inline-block;
        margin-left: 1em;
      } */
      
      .MuiTableHead-root th.descending::after {
        content: url(../images/sort-up-svg.png);
        display: inline-block;
        margin-left: 1em;
        height: 20px;
      }
